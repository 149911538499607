import { render, staticRenderFns } from "./ChatAssideItem.vue?vue&type=template&id=fcb97152&"
import script from "./ChatAssideItem.vue?vue&type=script&lang=js&"
export * from "./ChatAssideItem.vue?vue&type=script&lang=js&"
import style0 from "./ChatAssideItem.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VImg,VListItem,VListItemAvatar,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle})
